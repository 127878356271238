import Timer from 'react-timer'
import Logger from 'logger'
import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { v4 as uuidV4 } from 'uuid'
import { sparse } from 'ytil'
import config from '~/config'
import {
  isPendingMessage,
  Message,
  MessageList,
  PendingMessage,
  PendingMessageResource,
  PendingMessageTemplate,
  Sender,
} from '~/models'
import { mediaStore, MediaUploadResult } from '~/stores'
import chatStore from '../chatStore'
import { submitResultForResponse } from '../support/responses'
import ChatService from './ChatService'
import SendersEndpoint from './senders/SendersEndpoint'
import { ChatDescriptor, ChatState, MessagePayload, MessageStatusUpdate } from './types'

export default class ChatBackend {

  constructor(
    private readonly service: ChatService,
    descriptor: ChatDescriptor,
  ) {
    this._descriptor = descriptor
    makeObservable(this)
  }

  private readonly logger = new Logger('ChatBackend')

  private _descriptor: ChatDescriptor
  public get descriptor() {
    return this._descriptor
  }

  public get uri() {
    return this.descriptor.uri
  }

  private get socket() {
    return this.service.socket
  }

  @action
  public updateDescriptor(descriptor: ChatDescriptor) {
    this._descriptor = descriptor
  }

  // #region Properties

  private get profileID() {
    return this.service.profileID
  }

  @observable
  public loading: boolean = true

  @observable
  public senders = new Map<string, Sender>()

  @computed
  public get mostRecentMessage() {
    return this.messageList.mostRecentMessage
  }

  public get timestamp() {
    return this.mostRecentMessage?.timestamp ?? this.descriptor.createdAt.getTime()
  }

  @computed
  public get unreadCount() {
    return this.service.state.unreadCounts[this.uri] ?? 0
  }

  // #endregion

  // #region Senders

  public async fetchSenders(offset: number | null | undefined = 0, limit: number | null | undefined = 20) {
    const response = await this.socket.fetch('senders', this.uri, {
      limit:  limit,
      offset: offset,
    })

    return response
  }

  // #endregion

  //------
  // Message list

  @observable
  public messageList: MessageList = new MessageList()

  public nextPageToken: string | null = null

  //------
  // Incoming messages

  private incomingBuffer: MessagePayload[] = []

  public handleIncomingMessage(payload: MessagePayload): Promise<Message[]> {
    if (this.fetchingNewMessages) {
      // We're currently fetching messages. Queue them for processing after the fetch.
      this.incomingBuffer.push(payload)
      return Promise.resolve([])
    }

    this.senders.set(payload.sender.id, payload.sender)
    this.onStopTyping(payload.sender.id)

    const result = this.processIncomingMessages(payload)
    if (!result.contiguous) {
      // There's a gap. Refetch all new messages.
      const since = Math.min(...result.messages.map(msg => msg.timestamp)) - 1
      return this.fetchNewMessages(since)
    } else {
      return Promise.resolve(result.messages)
    }
  }

  private flushIncomingBuffer() {
    let allOK = true
    while (this.incomingBuffer.length > 0) {
      const payload = this.incomingBuffer.shift()!
      const result  = this.processIncomingMessages(payload)
      if (!result.contiguous) {
        allOK = false
      }
    }
    return allOK
  }

  @action
  private processIncomingMessages(payload: MessagePayload): ProcessIncomingMessagesResult {
    this.service.mergeState(payload.state)
    const message = Message.deserialize(payload.message)
    if (this.messageList?.get(message.id) != null) {
      this.messageList = this.messageList.updateMessage(message.id, () => message)
      return {contiguous: true, messages: [message]}
    }

    if (!this.messagesAreContiguous([message], payload.previousMessageID)) {
      // There's a gap.
      return {contiguous: false, messages: [message]}
    } else {
      this.messageList = this.prependMessages([message])
      return {contiguous: true, messages: [message]}
    }
  }

  private messagesAreContiguous(incoming: Message[], previousMessageID: string | null) {
    if (incoming.length === 0) { return true }

    const mostRecentMessage   = this.messageList?.mostRecentMessage
    const mostRecentMessageID = mostRecentMessage == null ? null : mostRecentMessage.id

    if (process.env.NODE_ENV === 'development') {
      this.logger.debug("Incoming messages. " + (previousMessageID === mostRecentMessageID ? "Contiguous 👍" : "Non-contiguous 😔"), [
        "Chat:         " + this.uri + ` (${this.descriptor.name})`,
        "Last message: " + mostRecentMessageID + ` [${mostRecentMessage?.timestamp}] (${mostRecentMessage?.text})`,
        "--------------",
        "Incoming[0]:  " + incoming[0].id + ` [${incoming[0].timestamp}] (${incoming[0].text})`,
        "Previous:     " + previousMessageID,
      ])
    }

    return previousMessageID === mostRecentMessageID
  }

  @action
  public updateMessageStatus(update: MessageStatusUpdate) {
    if (this.messageList == null) { return }

    const {messageID, status, answeredAt} = update
    this.messageList = this.messageList.updateMessage(messageID, message => {
      if (isPendingMessage(message)) {
        return message
      } else {
        return message.updateStatus({
          status:     status,
          answeredAt: answeredAt == null ? undefined : DateTime.fromISO(answeredAt),
        })
      }
    })
  }

  private appendMessages(messages: Message[]) {
    if (this.messageList == null) {
      return new MessageList(messages)
    } else {
      return this.messageList.appendMessages(messages)
    }
  }

  private removeMessagesSince(since: number) {
    if (this.messageList == null) {
      return new MessageList([])
    } else {
      const index = this.messageList.messagesDescending.findIndex(it => it.timestamp <= since)
      if (index < 0) { return new MessageList() }

      return this.messageList.slice(index)
    }
  }

  private prependMessages(messages: Message[]) {
    if (this.messageList == null) {
      return new MessageList(messages)
    } else {
      return this.messageList.prependMessages(messages)
    }
  }

  private prependPendingMessage(message: PendingMessage) {
    if (this.messageList == null) {
      return new MessageList([message])
    } else {
      return this.messageList.prependMessages([message])
    }
  }

  @action
  private updatePendingMessageStatus(id: string, status: PendingMessage['status']) {
    if (this.messageList == null) { return }
    this.messageList = this.messageList.updatePendingMessage(id, msg => ({...msg, status}))
  }

  private removeMessage(id: string) {
    if (this.messageList == null) { return }
    this.messageList = this.messageList.removeMessage(id)
  }

  // #region Typing

  @observable
  public readonly typing: Set<string> = new Set()

  public isTyping(senderID: string) {
    return this.typing.has(senderID)
  }

  @computed
  public get typingSenders(): Sender[] {
    return sparse(Array.from(this.typing).map(id => this.senders.get(id)))
  }

  @computed
  public get typingNames(): string[] {
    return this.typingSenders.map(sender => sender.firstName)
  }

  private autoStopTypingTimers: Map<string, Timer> = new Map()

  @action
  public onStartTyping = (sender: Sender) => {
    this.typing.add(sender.id)
    this.stopTypingSoon(sender.id)
  }

  @action
  public onStopTyping(senderID: string) {
    this.typing.delete(senderID)
    this.autoStopTypingTimers.get(senderID)?.clearAll()
    this.autoStopTypingTimers.delete(senderID)
  }

  private stopTypingSoon(senderID: string) {
    this.autoStopTypingTimers.get(senderID)?.clearAll()

    const timer = new Timer()
    this.autoStopTypingTimers.set(senderID, timer)

    timer.setTimeout(() => {
      this.onStopTyping(senderID)
    }, config.chat.typingTimeout)
  }

  public startTyping() {
    if (this.isTyping(this.profileID)) { return }

    this.typing.add(this.profileID)
    this.socket.emit('typing:start', this.uri)
  }

  public stopTyping() {
    if (!this.isTyping(this.profileID)) { return }

    this.typing.delete(this.profileID)
    this.socket.emit('typing:stop', this.uri)
  }

  // #endregion

  //------
  // Fetching

  private fetchingNewMessages: boolean = false

  @action
  public async fetchNewMessages(since?: number | null): Promise<Message[]> {
    this.fetchingNewMessages = true

    const mostRecentMessage   = this.messageList?.mostRecentMessage
    const mostRecentTimestamp = mostRecentMessage?.timestamp ?? null

    if (since == null || (mostRecentTimestamp != null && mostRecentTimestamp < since)) {
      since = mostRecentTimestamp
    }

    const response = await this.socket.fetch('fetch', this.uri, {since})
    if (response.ok) {
      return this.onFetchNewMessagesSuccess(response.body, since)
    } else {
      return this.onFetchNewMessagesError()
    }
  }

  @action
  private onFetchNewMessagesSuccess = (data: FetchMessagesData, since: number | null): Message[] => {
    const {senders, state, nextPageToken} = data
    const messages = data.messages.map(raw => Message.deserialize(raw))

    // Update the chat state.
    this.service.mergeState(state)
    // Store the senders.
    for (const sender of senders) {
      this.senders.set(sender.id, sender)
    }

    if (since != null && nextPageToken == null) {
      // We've received a new batch of messages since the given date. Remove all messages since this time,
      // and prepend the new messages. Keep the existing page token.
      this.messageList = this.removeMessagesSince(since)
      this.messageList = this.prependMessages(messages)
    } else {
      // We've either received our first batch, or there's so much new stuff that pagination is required in the
      // new set of messages. In both cases, replace the message list from here. As soon as the participant starts
      // scrolling back, the older messages will be fetched again.
      this.messageList = new MessageList(messages)

      // Also update the next page token.
      this.nextPageToken = nextPageToken
    }

    if (this.flushIncomingBuffer()) {
      this.fetchingNewMessages = false
    } else {
      // When flushing the incoming buffer, new incontiguities were encountered – perform a fresh
      // fetch just to be sure.
      this.fetchNewMessages()
    }

    return messages
  }

  @action
  private onFetchNewMessagesError = () => {
    this.loading = false
    this.fetchingNewMessages = false
    return []
  }

  //------
  // Pagination

  private fetchingNextPage: boolean = false

  @action
  public fetchNextPage(): Promise<boolean> {
    if (this.fetchingNextPage) {
      return Promise.resolve(false)
    }
    if (this.nextPageToken == null) {
      return Promise.resolve(false)
    }

    this.fetchingNextPage = true

    return this.socket.fetch('fetch', this.uri, {
      pageToken: this.nextPageToken,
    }).then(response => {
      if (response.ok) {
        return this.onFetchNextPageSuccess(response.body)
      } else {
        return this.onFetchNextPageError()
      }
    })
  }

  @action
  private onFetchNextPageSuccess = async (data: FetchMessagesData) => {
    const messages        = data.messages.map(raw => Message.deserialize(raw))
    this.nextPageToken    = data.nextPageToken
    this.messageList      = this.appendMessages(messages)
    this.fetchingNextPage = false

    return true
  }

  @action
  private onFetchNextPageError = () => {
    this.fetchingNextPage = false
    return false
  }

  //------
  // Sending

  @action
  public async sendMessage(template: Partial<PendingMessageTemplate> & {replyTo?: string | null}) {
    // Create a UUID for the message.
    const id = uuidV4()

    if (template.type !== 'skip') {
      // Create a pending message placeholder and prepend it.
      const pending: PendingMessage = {
        replyTo: null,
        ...template as PendingMessageTemplate,

        id:      id,
        status:  'pending',
        sentAt:  DateTime.local(),
      }

      this.messageList       = this.prependPendingMessage(pending)
    }

    if (template.replyTo != null) {
      this.markAsAnswered(template.replyTo)
    }

    // Now send the message. Only send the template and the ID. The socket will echo the message back.
    const result = await this.prepareMessage(id, template)
    if (result == null) {
      this.removeMessage(id)
    } else if (result?.status !== 'ok') {
      this.updatePendingMessageStatus(id, 'error')
    } else {
      await this.socket.send('message', this.uri, result.message)
    }
  }

  private async prepareMessage(id: string, template: Partial<PendingMessageTemplate>): Promise<PrepareMessageResult | null> {
    const templateWithMedia: AnyObject = template

    let result: MediaUploadResult | null = {status: 'ok', media: null as any}
    if (template.type === 'image') {
      result = await this.uploadAndInsertMedia(templateWithMedia, 'image')
    }
    if (template.type === 'video') {
      result = await this.uploadAndInsertMedia(templateWithMedia, 'video')
    }
    if (result?.status !== 'ok') { return result }

    return {
      status: 'ok',
      message: {
        id:      id,
        channel: this.uri,
        ...templateWithMedia,
      },
    }
  }

  private async uploadAndInsertMedia(template: AnyObject, key: string): Promise<MediaUploadResult | null> {
    const resource = template[key] as PendingMessageResource
    const result   = await mediaStore.storeMedia(resource.filename, resource.binary)

    if (result?.status === 'ok') {
      template[key] = {
        mediaID:     result.media.id,
        name:        result.media.name,
        contentType: result.media.contentType,
        url:         result.media.url,
      }
    }

    return result
  }

  //------
  // Moderation

  public async approveMessage(messageID: string) {
    const {messageList} = this
    const {sender} = this.service
    if (messageList == null || sender == null) { return }

    // Optimistically approve the message, and revert if something goes wrong.
    this.messageList = messageList.updateMessage(messageID, message => {
      if (isPendingMessage(message)) { return }
      return message.approve(sender.id)
    })

    const response = await this.socket.send('moderation:approve', this.uri, messageID)
    runInAction(() => {
      if (!response.ok) {
        this.messageList = messageList.updateMessage(messageID, message => {
          if (isPendingMessage(message)) { return }
          return message.undoModeration()
        })
      }
    })

    return submitResultForResponse(response)
  }

  public async redactMessage(messageID: string) {
    const {messageList} = this
    const {sender} = this.service
    if (messageList == null || sender == null) { return }

    // Optimistically redact the message, and revert if something goes wrong.
    this.messageList = messageList.updateMessage(messageID, message => {
      if (isPendingMessage(message)) { return }
      return message.redact(sender.id)
    })

    const response = await this.socket.send('moderation:redact', this.uri, messageID)
    runInAction(() => {
      if (!response.ok) {
        this.messageList = messageList.updateMessage(messageID, message => {
          if (isPendingMessage(message)) { return }
          return message.undoModeration()
        })
      }
    })

    return submitResultForResponse(response)
  }

  //------
  // Message status

  @action
  public markAsRead() {
    if (this.shouldSendReadReceipt) {
      this.socket.emit('status:read', this.uri)
    }

    this.service.mergeState({
      totalUnreadCount: this.service.state.totalUnreadCount - this.unreadCount,
      unreadCounts: {
        [this.uri]: 0,
      },
    })
  }

  private get shouldSendReadReceipt() {
    // Early optimization: the server doesn't process read receipts if there are too many participants in the channel.
    // The client will not know about all senders, but if the client knows there are more than this number, there is no
    // need to send a read receipt.
    if (this.senders.size > config.chat.maxReceipts) { return false }

    return true
  }

  @action
  public markAsAnswered(messageID: string, answeredAt: DateTime = DateTime.local()) {
    if (this.messageList == null) { return null }

    this.messageList = this.messageList.updateMessage(
      messageID,
      message => message instanceof Message ? message.markAsAnswered(answeredAt) : message,
    )
  }

  //------
  // Reset

  @action
  public reset() {
    this.messageList = new MessageList()
    this.loading = true
    this.typing.clear()
  }

  //------
  // Senders endpoint

  private _sendersEndpoint?: SendersEndpoint
  public sendersEndpoint() {
    return this._sendersEndpoint ??= this.createSendersEndpoint()
  }
  private createSendersEndpoint() {
    const database = chatStore.senders
    return new SendersEndpoint(database, this.service)
  }

}

interface FetchMessagesData {
  messages:      Message[]
  senders:       Sender[]
  state:         ChatState
  nextPageToken: string | null
}

type PrepareMessageResult =
  | {status: 'ok', message: AnyObject}
  | {status: 'canceled'}
  | {status: 'invalid', reason: string}
  | {status: 'error'}

type ProcessIncomingMessagesResult =
  | {contiguous: false, messages: Message[]}
  | {contiguous: true, messages: Message[]}