import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps } from 'react-router-dom'
import { BeWizrLibraryItem } from '~/models'
import { bewizrLibraryStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, HBox, List, Spinner, VBox } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { createUseStyles, layout } from '~/ui/styling'
import { screenWidths } from '~/ui/styling/layout'
import BeWizrCourseTile from '../bewizr-courses/BeWizrCourseTile'
import BeWizrLearningTrackTile from '../bewizr-learning-tracks/BeWizrLearningTrackTile'
import BeWizrLibraryFilters from '../bewizr-library/BeWizrLibraryFilters'
import BeWizrMeetingTile from '../bewizr-meetings/BeWizrMeetingTile'

export interface BeWizrLibraryParams {
  topic?: string
}

export type Props = RouteComponentProps<BeWizrLibraryParams>

const BeWizrLibraryScreen = observer('BeWizrLibraryScreen', (props: Props) => {

  const [t] = useTranslation('bewizr-library')

  const {topic} = props.match.params

  const topicDocument    = topic == null ? null : bewizrLibraryStore.topics.document(topic)
  const topicFetchStatus = topicDocument?.fetchStatus ?? 'done'

  const title = topicDocument?.data?.title ?? t('title')

  const endpoint = React.useMemo(() => {
    if (topicDocument != null) {
      return topicDocument.itemsEndpoint
    } else {
      return bewizrLibraryStore.allItems
    }
  }, [topicDocument])

  const fetchStatus = endpoint.fetchStatus
  const items       = endpoint.data

  const filtersEnabled = endpoint.param('query')
    || endpoint.param('type')
    || (topic == null && endpoint.param('topic'))
    || endpoint.param('category')

  const fetchMore = React.useCallback(() => {
    endpoint?.fetchMore()
  }, [endpoint])

  const fetchTopic = React.useCallback(() => {
    topicDocument?.fetch()
  }, [topicDocument])

  React.useEffect(() => {
    fetchTopic()
  }, [fetchTopic])

  const isTablet = useMediaQuery({minWidth: screenWidths.tablet})

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={title}
          fetchStatus={topicFetchStatus}
          ActionsComponent={renderSpinner}
        />
        {isTablet ? (
          <HBox align='stretch' flex>
            {renderContent()}
          </HBox>
        ) : (
          <VBox flex>
            {renderContent()}
          </VBox>
        )}
      </>
    )
  }

  function renderSpinner() {
    if (fetchStatus !== 'fetching') { return null }
    return (
      <Spinner dim size={12}/>
    )
  }

  function renderContent() {
     return (
      <>
        {renderFilters()}
        <VBox flex>
          <List
            data={items}
            onEndReached={fetchMore}
            renderItem={renderItem}
            EmptyComponent={renderEmpty}
            itemGap={layout.padding.s}
            contentPadding={layout.padding.m}
            scrollable
          />
        </VBox>
      </>
     )
  }

  const renderEmpty = React.useCallback(() => (
    <EmptyOrFetching
      {...t(`empty.${filtersEnabled ? 'with_query' : 'without_query'}`)}
      status={fetchStatus}
      flex
    />
  ), [t, filtersEnabled, fetchStatus])

  function renderFilters() {
    return (
      <VBox classNames={$.filters} gap={layout.padding.s} padding={layout.padding.m}>
        <BeWizrLibraryFilters
          endpoint={endpoint}
          topic={topic}
        />
      </VBox>
    )
  }

  function renderItem(item: BeWizrLibraryItem) {
    if (item.type === 'course') {
      return (
        <BeWizrCourseTile
          title={item.title}
          slug={item.slug}
          image={item.links.image}
          progress={false}
          horizontal
        />
      )
    } else if (item.type === 'learning_track') {
      return (
        <BeWizrLearningTrackTile
          title={item.title}
          image={item.links.image}
          id={item.id}
          horizontal
        />
      )
    } else if (item.type === 'meeting') {
      return (
        <BeWizrMeetingTile
          meetingID={item.id}
          title={item.title}
          image={item.links.image}
          horizontal
        />
      )
    } else {
      console.warn(`Learning activity "${item.type}" not supported`)
      return null
    }
  }

  return render()

})

export default BeWizrLibraryScreen

const useStyles = createUseStyles(theme => ({
  filters: {
    ...layout.tablet({
      width: 264,
      borderRight: [2, 'solid', theme.colors.border.dimmer],
    }),
  },
}))