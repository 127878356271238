import React from 'react'
import { useTranslation } from 'react-i18next'
import { Media } from '~/models'
import { ImageView, VideoView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Center, ClearButton, Tappable, VBox, VBoxProps } from '~/ui/components'
import { colors, createUseStyles, layout, ThemeProvider } from '~/ui/styling'

export interface Props {
  media: Media
  size?: Size
  flex?: VBoxProps['flex']

  onTap?:         (media: Media) => any
  requestRemove?: (media: Media) => any
}

const PostMediaThumbnail = memo('PostMediaThumbnail', (props: Props) => {

  const {media, size, flex, onTap, requestRemove} = props

  const [t] = useTranslation('news')

  const tap = React.useCallback(() => {
    onTap?.(media)
  }, [media, onTap])

  const remove = React.useCallback(() => {
    requestRemove?.(media)
  }, [media, requestRemove])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex={flex} classNames={$.PostMediaThumbnail}>
        {onTap != null ? (
          <Tappable flex={flex} onTap={tap}>
            {renderMedia()}
          </Tappable>
        ) : renderMedia()}

        {requestRemove != null && (
          <Center classNames={$.remove}>
            <ThemeProvider dark>
              <ClearButton
                icon='cross'
                caption={t('post_form.remove_media')}
                onTap={remove}
                padding='horizontal'
                small
              />
            </ThemeProvider>
          </Center>
        )}
      </VBox>
    )
  }

  function renderMedia() {
    if (/^image\//.test(media.contentType)) {
      return (
        <ImageView
          source={media.url}
          size={size}
          flex={flex}
        />
      )
    } else {
      return (
        <VideoView
          source={media.url}
          size={size}
          flex={flex}
          overlay
        />
      )
    }
  }

  return render()

})

export default PostMediaThumbnail

const useStyles = createUseStyles({
  PostMediaThumbnail: {
    position: 'relative',
  },

  remove: {
    position:   'absolute',
    bottom:     0,
    left:       0,
    right:      0,
    padding:    [layout.padding.inline.s, layout.padding.inline.m],
    background: colors.shim.dark,
  },
})