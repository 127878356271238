import React from 'react'
import { useTranslation } from 'react-i18next'
import { objectEquals } from 'ytil'
import { memo } from '~/ui/component'
import { HBox, ModalDialog, PushButton, Scroller, SearchField, VBox } from '~/ui/components'
import { useBoolean, usePrevious, useQueryString } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import FiltersContext from './FiltersContext'

export interface Props {
  children:    React.ReactNode
  onCommit:    (params: Record<string, string | string[]>) => void
  onReset:     () => void
  searchEnabled?: boolean
  collapsed?:     boolean
}

const FiltersForm = memo('FiltersForm', (props: Props) => {

  const {onCommit, onReset, searchEnabled = true, collapsed = true, children} = props

  const [modalOpen, openModal, closeModal] = useBoolean()

  const [t] = useTranslation('filters')

  const {params, setParams} = useQueryString()

  const query = params.get('query') ?? ''
  const setQuery = React.useCallback((query: string | null) => {
    setParams({query})
  }, [setParams])

  const value = React.useMemo((): Record<string, string | string[]> => {
    return Array.from(params).reduce((result, [key, value]) => ({
      ...result,
      [key]: value.includes(',') ? value.split(',') : value,
    }), {})
  }, [params])

  const previousValue = usePrevious(value)

  const count = React.useMemo(() => {
    if (searchEnabled) {
      return Object.keys(value).filter(it => it !== 'query').length
    } else {
      return Object.keys(value).length
    }
  }, [searchEnabled, value])

  React.useEffect(() => {
    if (objectEquals(value, previousValue)) {
      return
    } else if (Object.keys(value).length === 0) {
      onReset()
    } else {
      onCommit(value)
    }
  }, [onCommit, onReset, value, previousValue])

  const assign = React.useCallback((name: string, value: string | string[] | null) => {
    setParams({
      ...params,
      [name]: value,
    })
  }, [params, setParams])

  const context = React.useMemo(() => ({
    value:    value,
    count:    count,
    assign:   assign,
    onCommit: onCommit,
  }), [value, count, assign, onCommit])

  //------
  // Rendering

  function render() {
    return (
      <FiltersContext.Provider value={context}>
        {collapsed ? (
          <HBox gap={layout.padding.inline.m}>
            <VBox flex>
              {renderSearch()}
            </VBox>
            <PushButton
              icon='filter'
              caption={t('title')}
              onTap={openModal}
            />
          </HBox>
        ) : (
          <VBox gap={layout.padding.l}>
            {renderSearch()}
            {renderFilters()}
          </VBox>
        )}
        {renderModal()}
      </FiltersContext.Provider>
    )
  }

  function renderModal() {
    if (!collapsed) { return null }

    return (
      <ModalDialog
        title={t('title')}
        headerRight='$close'
        icon='filter'
        open={modalOpen}
        requestClose={closeModal}
        width='max'
        height='max'

      >
        <VBox flex>
          <Scroller flex contentPadding={layout.padding.m}>
            {renderFilters()}
          </Scroller>
          <VBox padding={layout.padding.m}>
            <PushButton
              caption={t('apply')}
              onTap={closeModal}
            />
          </VBox>
        </VBox>

      </ModalDialog>
    )
  }

  function renderSearch() {
    return (
      <SearchField
        initialSearch={query}
        onSearch={setQuery}
        debounce={200}
      />
    )
  }

  function renderFilters() {
    return (
      <VBox gap={layout.padding.l}>
        {children}
      </VBox>
    )
  }

  return render()

})

export default FiltersForm