import React from 'react'
import { uniq } from 'lodash'
import { CustomImage } from '~/models'
import { memo } from '~/ui/component'
import { Checkbox, HBox, Label, List, Panel, VBox } from '~/ui/components'
import SVG, { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'
import { ImageView } from '../media'
import FiltersContext from './FiltersContext'

export interface Props<T> {
  name:             string
  choices:          T[]
  caption:          string
  imageForChoice:   (choice: T) => SVGName | CustomImage
  captionForChoice: (choice: T) => string
  valueForChoice:   (choice: T) => string
  multi?:           boolean
}

const _FilterFieldSet = <T extends {} | string>(props: Props<T>) => {

  const {name, choices, caption, imageForChoice, captionForChoice, valueForChoice, multi = false} = props

  const {value: activeFilters, assign} = React.useContext(FiltersContext)

  const currentValue = React.useMemo((): string[] => {
    const value = activeFilters[name]
    if (value == null) {
      return []
    } else if (typeof value === 'string') {
      return [value]
    } else {
      return value
    }
  }, [activeFilters, name])

  const onChange = React.useCallback((value: string, checked: boolean) => {
    if (!multi) {
      assign(name, checked ? value : null)
    } else {
      const newValue = checked ? uniq([...currentValue, value]) : currentValue.filter(it => it !== value)
      if (newValue.length === 0) {
        assign(name, null)
      } else {
        assign(name, newValue)
      }
    }
  }, [multi, name, assign, currentValue])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <Label caption dim>
          {caption}
        </Label>
        <List
          data={choices}
          renderItem={renderItem}
          itemGap={layout.padding.inline.s}
          flex={false}
        />
      </VBox>
    )
  }

  function renderItem(choice: T) {
    const value = valueForChoice(choice)
    return (
      <FilterFieldSetRow
        value={value}
        checked={currentValue.includes(value)}
        caption={captionForChoice(choice)}
        image={imageForChoice(choice)}
        onChange={onChange}
      />
    )
  }

  return render()

}

export default memo('FilterFieldSet', _FilterFieldSet) as typeof _FilterFieldSet

export const rowHeight = layout.barHeight.xs

const useStyles = createUseStyles({
  FilterFieldSetRow: {
    height:       rowHeight,
    paddingRight: layout.padding.inline.m,
    overflow:     'hidden',
  },

  FilterFieldSetRow__icon: {
    marginLeft: layout.padding.inline.m,
  },
})

interface FilterFieldSetRowProps {
  value:    string
  checked:  boolean
  onChange: (value: string, checked: boolean) => void
  image:    SVGName | CustomImage
  caption:  string
}

const FilterFieldSetRow = memo('FilterFieldSetRow', (props: FilterFieldSetRowProps) => {

  const {value, checked, onChange, image, caption} = props

  const tap = React.useCallback(() => {
    onChange(value, !checked)
  }, [value, checked, onChange])

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={$.FilterFieldSetRow} onTap={tap}>
        <HBox gap={layout.padding.inline.m} flex>
          {renderImage()}
          <Label caption flex>
            {caption}
          </Label>
          <Checkbox small checked={checked}/>
        </HBox>
      </Panel>
    )
  }

  function renderImage() {
    if (typeof image === 'string') {
      return (
        <SVG
          name={image}
          size={layout.icon.m}
          classNames={$.FilterFieldSetRow__icon}
        />
      )
    } else {
      return (
        <ImageView
          size={{width: rowHeight, height: rowHeight}}
          source={image}
        />
      )
    }
  }

  return render()

})