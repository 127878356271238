import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { BeWizrLibraryItemType } from '~/models'
import { BeWirzLibraryItemsParams, BeWizrLibraryItemsEndpoint, bewizrLibraryStore } from '~/stores'
import { observer } from '~/ui/component'
import { screenMinWidths } from '~/ui/styling/layout'
import { FilterFieldSet, FiltersForm } from '../filters'

export interface Props {
  endpoint: BeWizrLibraryItemsEndpoint
  topic?:   string
}

const BeWizrLibraryFilters = observer('BeWizrLibraryFilters', (props: Props) => {

  const {endpoint, topic} = props

  const [t] = useTranslation('bewizr-library')

  const topicDocument  = topic == null ? null : bewizrLibraryStore.topics.document(topic)
  const topicsEndpoint = bewizrLibraryStore.allTopics
  const topics         = topicsEndpoint.data

  const categoriesEndpoint = React.useMemo(() => {
    if (topicDocument != null) {
      return topicDocument.categoriesEndpoint
    } else {
      return bewizrLibraryStore.allCategories
    }
  }, [topicDocument])
  const categories = categoriesEndpoint.data

  React.useEffect(() => {
    if (topic == null) {
      topicsEndpoint.fetch()
      categoriesEndpoint.resetAndFetch()
    } else {
      categoriesEndpoint.fetchWithParams({topic})
    }
  }, [topic, topicsEndpoint, categoriesEndpoint])

  const filter = React.useCallback((params: Partial<BeWirzLibraryItemsParams>) => {
    if (params.topic != null) {
      categoriesEndpoint.fetchWithParams({
        topic: topic ?? params.topic,
      })
    } else {
      categoriesEndpoint.resetAndFetch()
    }
    endpoint.resetAndFetch(topic == null ? params : {...params, topic})
  }, [endpoint, categoriesEndpoint, topic])

  const reset = React.useCallback(() => {
    endpoint.resetAndFetch({topic})
    categoriesEndpoint.resetAndFetch({topic})
  }, [endpoint, categoriesEndpoint, topic])

  const collapsed = !useMediaQuery({minWidth: screenMinWidths.tablet})

  //------
  // Rendering

  function render() {
    return (
      <FiltersForm
        onCommit={filter}
        onReset={reset}
        collapsed={collapsed}
      >
        {renderType()}
        {renderTopic()}
        {renderCategory()}
      </FiltersForm>
    )
  }

  function renderType() {
    return (
      <FilterFieldSet
        name='type'
        choices={BeWizrLibraryItemType.all}
        caption={t('item-types.caption')}
        imageForChoice={BeWizrLibraryItemType.iconForType}
        captionForChoice={captionForType}
        valueForChoice={type => type}
        multi={false}
      />
    )
  }

  function renderTopic() {
    if (topic != null || topics.length === 0) { return null }

    return (
      <FilterFieldSet
        name='topic'
        choices={topics}
        caption={t('topics.title')}
        imageForChoice={topic => topic.links.image == null ? 'archive' : ({
          type: 'remote',
          url:  topic.links.image,
        })}
        captionForChoice={topic => topic.title}
        valueForChoice={topic => topic.id}
        multi={false}
      />
    )
  }

  function renderCategory() {
    const fetchingCategories = categoriesEndpoint.fetchStatus === 'fetching'
    if (topic == null || endpoint.param('topic') == null || fetchingCategories || categories.length < 2) { return null }

    return (
      <FilterFieldSet
        name='category'
        choices={categories}
        caption={t('categories.title')}
        imageForChoice={() => 'folder'}
        captionForChoice={category => category.title}
        valueForChoice={category => category.id}
        multi={false}
      />
    )
  }

  const captionForType = React.useCallback((type: BeWizrLibraryItemType) => {
    return t(`item-types.${type}`)
  }, [t])

  return render()

})

export default BeWizrLibraryFilters
